
.cloud:first-of-type {
    width: 100px;
    bottom: 0;
    left: 50%;
}
.cloud:nth-of-type(2){
    width: 150px;
    bottom: 10%;
    right: 10%;
}
.cloud:nth-of-type(3){
    width: 200px;
    bottom: 22%;
    left: 13%;
}
.cloud:nth-of-type(4){
    width: 250px;
    bottom: 5%;
    left: 33%;
}
.cloud:nth-of-type(5){
    width: 300px;
    bottom: 30%;
    right: 25%;
}
.cloud:nth-of-type(6){
    width: 225px;
    bottom: 3%;
    left: -15%;
}
.cloud:nth-of-type(7){
    width: 175px;
    bottom: 24%;
    left: 0%;
}
.cloud {
    position: absolute;
    z-index: 0;
}

// for mobile
@media screen and (max-width: 450px) {
    .cloud {
        position: relative;
    }
    .cloud:nth-of-type(6){
        width: 225px;
        bottom: 3%;
        left: -100%;
    }
    .cloud:nth-of-type(2){
        width: 150px;
        bottom: 10%;
        left: -75%;
    }
}


// .cloud-container {
//     // display: flex;\
//     position: absolute;
// }